import React, {useEffect} from 'react'
import { graphql } from "gatsby"
 
import Seo from "../components/Seo"
import Splitting from "splitting";
import ScrollOut from "scroll-out";

import H1 from '../components/H1';
 
// markup
const IndexPage = ({data}) => {
  const {
    wpPage: { title, content, seo },
  } = data

  useEffect(() => {
    // do something
    Splitting();
    const scroll = ScrollOut({once:true});

    setTimeout(() => {
      scroll.update();
    },300);

    return () => {
      scroll.teardown();
    }
  }, []);

  return (
    <>
    <Seo title={seo.title} description={seo.metaDesc} />
      <H1 title={title } />
      
    

       <div  className="container-fluid mb-20 mb-md-40">
 

        <div data-scroll="out" className="col-md-6 offset-md-6 col-xl-5 fade-in">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    
 
      </div>

    </>
  )
}


export const query = graphql`
{
  wpPage(databaseId: {eq: 2238}) {
    title
    content
    seo {
      title
      metaDesc
    }
  }
}
`

export default IndexPage
